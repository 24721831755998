<template>
  <div>
    <b-row>
      <b-col lg="3">
        <b-form-group
          label="Username"
          label-for="username">
          <b-form-input
            id="username"
            v-model="user.username"
            disabled />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="ACL UUID"
          label-for="acl_uuid">
          <b-form-input
            id="acl_uuid"
            v-model="user.acl_uuid"
            disabled />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Email"
          label-for="email">
          <b-form-input
            id="email"
            v-model="user.email"
            :disabled="!$acl.check('users.edit')"
            type="email" />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="First Name"
          label-for="firstName">
          <b-form-input
            id="firstName"
            v-model="user.firstname"
            :disabled="!$acl.check('users.edit')" />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Last Name"
          label-for="lastName">
          <b-form-input
            id="lastName"
            v-model="user.lastname"
            :disabled="!$acl.check('users.edit')" />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Phone Number"
          label-for="phone">
          <b-form-input
            id="phone"
            v-model="user.telephone"
            :disabled="!$acl.check('users.edit')"
            type="tel" />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Twilio Phone Number"
          label-for="externalPhoneNumber">
          <b-form-input
            id="externalPhoneNumber"
            v-model="user.external_phone_number"
            :disabled="!$acl.check('users.edit')"
            type="tel" />
        </b-form-group>

        <InputFeedbackMessage
          v-if="!isTwilioPhoneNumberValid"
          class="text-danger"
          data-testid="userPhoneInputMessageSMSComplaint"
          icon-name="alert-triangle"
          message="This Phone Number is not eligible for SMS" />
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Metric System"
          label-for="metricSystem">
          <b-form-select
            id="metricSystem"
            v-model="user.measurement_system"
            :disabled="!$acl.check('users.edit')"
            required>
            <b-form-select-option value="metric">Metric</b-form-select-option>
            <b-form-select-option value="imperial">Imperial</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col v-if="'Professional' === user.role && user.unit !== 'move'" lg='3'>
        <b-form-group
          label="NPI"
          label-for="npi">
          <b-form-input
            id="npi"
            v-model="user.npi"
            :disabled="!$acl.check('users.edit')"
            maxlength="10" />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group label="Locations">
          <multiselect
                id="input-locations"
                v-model="user.locations"
                tag-placeholder=""
                placeholder=""
                :options="allowedUserLocations"
                track-by="value"
                label="name"
                :max="1"
                :multiple="true"
                :taggable="false"></multiselect>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group label="Conversational Languages">
          <b-form-checkbox-group
            id="user-conversational-language-selection"
            v-model="user.languages"
            :options="languages" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3" style="row-gap: 1rem">
      <b-col lg="3" md="6">
        <b-card header="User data level access">
          <b-card-text>
            <p class="text-capitalize font-weight-bold mb-2">{{ user.data_level_access }}</p>
            <small>This value is not changeable</small>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col v-if="user.unit" lg="3" md="6">
        <b-card header="Ecosystem Unit">
          <b-card-text>
            <p class="text-capitalize font-weight-bold mb-2">{{ user.unit }}</p>
            <small>This value is not changeable</small>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="user.unit !== 'move'" class="mt-3">
      <template v-if="isCareCoordinator(user)">
        <b-col>
          <b-card>
            <b-form-group
              class="mb-0"
              label="Care Coordinator"
              label-class="font-weight-bold pt-0"
              label-size="lg">
              <b-form-group
                label="Unit Tags"
                label-class="font-weight-bold"
                label-for="unit-tags">
                <b-form-checkbox-group
                  id="unit-tags"
                  v-model="user.units"
                  :options="unitTagsOptions"
                  switches />
              </b-form-group>

              <b-form-group
                label="Assignment Type"
                label-class="font-weight-bold"
                label-for="radio-group-assignment-type">
                <b-form-radio-group
                  id="radio-group-assignment-type"
                  v-model="user.assignment_type"
                  :options="assignmentTypeOptions"
                  name="radio-group-assignment-type"
                  stacked />
              </b-form-group>
            </b-form-group>

            <b-form-group
              label="Transfer Members"
              label-class="font-weight-bold"
              label-for="transfer-cc-members">
              <b-button
                class="ml-3"
                @click="transferMembers"
                type="button"
                :disabled="isTransferringMembers"
                variant="primary">
                <b-spinner
                  label="Requesting transfer..."
                  variant="light"
                  small
                  v-if="isTransferringMembers">
                </b-spinner>
                <span v-else>Transfer</span>
              </b-button>
            </b-form-group>
          </b-card>
        </b-col>
      </template>

      <template v-else>
        <b-col lg="6" xl="3">
          <b-card header="Program Type">
            <b-card-text>
              <b-form-group v-slot="{ ariaDescribedby }" label="">
                <b-form-radio-group
                  id="radio-group-program-type"
                  v-model="user.program_type"
                  :aria-describedby="ariaDescribedby"
                  :options="programTypeOptions"
                  name="radio-group-program-type"
                  stacked />
              </b-form-group>
            </b-card-text>
          </b-card>
        </b-col>

        <b-col v-if="isProgramTypeVirtualPT(user)" lg="6" xl="3">
          <b-card header="Provider Strategy">
            <b-form-checkbox id="has_provider_enabled" v-model="user.has_provider_enabled"
                             name="has_provider_enabled" switch>
              Has Provider Enabled
            </b-form-checkbox>
          </b-card>
        </b-col>
      </template>
    </b-row>

    <b-row class="mt-3" style="row-gap: 1rem">
      <slot></slot>
    </b-row>
  </div>
</template>

<script>
import { professionalsLanguages } from '@/scripts/definitions';
import userMixin from '@/mixins/user';
import Multiselect from 'vue-multiselect';
import InputFeedbackMessage from '@/components/InputFeedbackMessage.vue';
import {
  UNIT_BLOOM,
  UNIT_MOVE,
  UNIT_SWORD,
  UNIT_MIND,
  USER_LOCATIONS,
} from '@/scripts/constants';
import axios from 'axios';

export default {
  mixins: [ userMixin ],
  components: { InputFeedbackMessage, Multiselect },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isTwilioPhoneNumberValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      languages: professionalsLanguages.map(language => ({
        value: language.value,
        text: language.text,
      })),
      user: {},
      dataLevelAccessOptions: [
        {
          value: 'normal',
          html: '<b>Normal</b>: User will not be allowed to see data from strict US clients',
        },
        {
          value: 'strict',
          html: '<b>Strict - Zero Tolerance</b>: User will be allowed to see data from all clients including strict US',
        },
      ],
      programTypeOptions: [
        {
          value: 'VIRTUAL_PT',
          html: '<b>Virtual PT</b>: PT for physical therapy',
        },
        {
          value: 'PREVENTIVE',
          html: '<b>Preventive</b>: User for preventive members',
        },
      ],
      assignmentTypeOptions: [
        {
          value: 'normal',
          // eslint-disable-next-line max-len
          html: '<b>Normal</b>: Idlest one will be assigned new members when their backlog is at most double the size of the idlest "Half" CC\'s backlog',
        },
        {
          value: 'half',
          // eslint-disable-next-line max-len
          html: '<b>Half</b>: Idlest one will be assigned new members when their backlog is less than half the size of the idlest "Normal" CC\'s backlog',
        },
        {
          value: 'none',
          html: '<b>None</b>: Not considered for assignment',
        },
      ],
      unitTagsOptions: [
        {
          text: 'DPT',
          value: UNIT_SWORD,
        },
        {
          text: 'Bloom',
          value: UNIT_BLOOM,
        },
        {
          text: 'Move',
          value: UNIT_MOVE,
        },
        {
          text: 'Mind',
          value: UNIT_MIND,
          disabled: true,
        },
      ],
      isTransferringMembers: false,
      locationOptions: [
        { location: 'canelas', label: 'Canelas' },
        { location: 'salt_lake', label: 'Salt Lake' },
      ],
    };
  },
  name: 'UserPersonalInfo',
  beforeMount() {
    this.user = this.value;

    // Map locations to their names
    if (this.user.locations) {
      this.user.locations = this.user.locations.map(location => {
        const locationKey = Object.keys(USER_LOCATIONS).find(key => USER_LOCATIONS[key].value === location);
        return {
          value: location,
          name: locationKey ? USER_LOCATIONS[locationKey].name : location,
        };
      });
    }
  },
  watch: {
    user(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    async transferMembers() {
      this.isTransferringMembers = true;
      const ccId = this.$route.params.id;
      await axios.post(`v1/care-coordinators/${ccId}/transfer/all`)
        .then(() => this.$noty.success('Members transfer requested with success'))
        .catch(e => {
          this.$noty.error('An error occurred when requesting members transfer');
          console.debug(e);
        });
      this.isTransferringMembers = false;
    },
  },
};
</script>
