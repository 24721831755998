<template>
  <b-form-group label="Extra user roles">
    <b-form-checkbox-group
        id="user-extra-roles"
        v-model="selectedRoles"
        :options="extraRoles"
        name="user-extra-roles"
        stacked>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { ROLES } from '@/scripts/constants';

export default {
  name: 'ExtraRoles',
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      selectedRoles: [],
      extraRoles: [
        { checked: false, value: ROLES.support, text: 'Support' },
        { checked: false, value: ROLES.production, text: 'Supply chain member' },
        { checked: false, value: ROLES.licensing, text: 'Manage therapist licenses' },
        { checked: false, value: ROLES.finance, text: 'Manage financial settings' },
        { checked: false, value: ROLES.academy, text: 'Manage Academy Content' },
        { checked: false, value: ROLES.release_manager, text: 'Manage ROM and APK Releases (CSM)' },
        { checked: false, value: ROLES.client_revision_approver, text: 'Client Revision Approver' },
        { checked: false, value: ROLES.dpt_operator, text: 'DPT Operator' },
        { checked: false, value: ROLES.dpt_manager_operator, text: 'DPT Manager Operator' },
        { checked: false, value: ROLES.bloom_operator, text: 'Bloom Operator' },
        { checked: false, value: ROLES.bloom_manager_operator, text: 'Bloom Manager Operator' },
        { checked: false, value: ROLES.refurbishment_operator, text: 'Refurbishment Operator' },
        { checked: false, value: ROLES.esm_manager_operator, text: 'ESM Manager Operator' },
        { checked: false, value: ROLES.uartronica_operator, text: 'Uartronica Operator' },
      ],
    };
  },
  beforeMount() {
    this.selectedRoles = this.value;
  },
  watch: {
    selectedRoles(rolesChanged) {
      this.$emit('input', rolesChanged);
    },
  },
};
</script>

<style scoped>

</style>
