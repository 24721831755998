<template>
  <div class="container">
    <b-row class="mt-3">
      <b-col>
        <h4>Editing {{ user.role }} {{ user.firstname }} {{ user.lastname }}</h4>
      </b-col>
    </b-row>
    <form v-if="user.id" novalidate>
      <div class="row mt-4">
        <div class="col-12">
          <h4>Personal information</h4>
        </div>
      </div>
      <UserPersonalInfo v-model="user"></UserPersonalInfo>
      <b-row v-if="$acl.check('update.user.roles')" class="mt-4">
        <b-col>
          <ExtraRoles v-model="user.extra_roles"></ExtraRoles>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="d-flex justify-content-center">
          <button :disabled="saving"
                  class="btn btn-primary"
                  type="button"
                  @click="saveUser">
            Save
          </button>
        </b-col>
      </b-row>
    </form>
    <div v-else class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { omitBy } from 'lodash';
import utils from '@/scripts/tools/utils';
import userMixin from '@/mixins/user';
import UserPersonalInfo from '../../components/UserPersonalInfo.vue';
import ExtraRoles from './partial/ExtraRoles.vue';

export default {
  name: 'UserEdit',
  components: { ExtraRoles, UserPersonalInfo },
  mixins: [ userMixin ],
  data() {
    return {
      user: {
        id: null,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        username: '',
        data_level_access: '',
        program_type: '',
        has_provider_enabled: false,
        is_contractor: false,
        tags: [],
        extra_roles: [],
        assignment_type: '',
      },
      saving: false,
      userId: null,
      loadingUser: true,
      available_user_tags: [
        'contractor',
      ],
      originalData: {},
    };
  },
  beforeMount() {
    this.userId = this.$route.params.id;
    Promise.all([ this.requestInstitutions(), this.requestUser() ]).finally(() => {
      /* eslint-disable */
      for (const institution of this.institutions) {
        const userInstitution = this.userInstitutions.find(i => i.institutionID === institution.institutionID);

        if (!userInstitution) {
          this.userInstitutionsMap[institution.institutionID].enabled = false;
          this.userInstitutionsMap[institution.institutionID].available_for_onboarding = false;
          continue;
        }
        this.userInstitutionsMap[institution.institutionID].enabled = true;
        if (!this.isProfessional(this.user)) {
          continue;
        }
        const professional = this.professionals.find(p => p.institutionRefID === userInstitution.institutionID);
        let { available_for_onboarding: availableForOnboarding } = professional;
        availableForOnboarding = availableForOnboarding !== null ? availableForOnboarding : true;
        this.userInstitutionsMap[institution.institutionID].availableForOnboarding = Boolean(availableForOnboarding);
      /* eslint-enable */
      }
    });
  },
  computed: {
    is_contractor: {
      get() {
        const tags = this.user.tags || [];
        return tags.includes('contractor');
      },
      set(value) {
        this.user.tags = this.user.tags || [];
        const isAlreadyTagged = this.user.tags.includes('contractor');
        if (value) {
          if (isAlreadyTagged) {
            return;
          }
          this.user.tags.push('contractor');
          return;
        }
        this.user.tags.splice(this.user.tags.findIndex(v => v === 'contractor'), 1);
      },
    },
  },
  methods: {
    async requestUser() {
      this.loadingUser = true;
      return axios.get(`/v1/users/${this.userId}`, { params: { client: 'back-office' } }).then(({ data }) => {
        data.user.roles = data.user.roles || [];
        data.user.has_provider_enabled = !!data.user.has_provider_enabled;
        data.user.extra_roles = [ ...new Set(data.user.roles.map(role => role.name)) ];
        data.user.languages = (data.user.languages || []).map(language => language.code);
        this.originalData = JSON.parse(JSON.stringify({
          user: data.user,
          professionals: data.professionals,
          institutions: data.institutions,
          institutionsMap: data.userInstitutionsMap,
        }));

        if (data.careCoordinator) {
          data.user.assignment_type = data.careCoordinator.assignment_type;
          data.user.units = data.careCoordinator.units.map(({ unit }) => unit);
        }

        this.user = Object.assign({}, this.user, data.user);
        this.professionals = data.professionals;
        if (this.professionals && this.professionals.length > 0) {
          const { tags } = this.professionals[0];
          this.user.tags = tags || [];
        }
        this.userInstitutions = data.institutions;
      }).catch(err => {
        console.error(err);
        this.$noty.error('Failed to fetch user: ', err);
      }).finally(() => {
        this.loadingUser = false;
      });
    },

    async requestInstitutions() {
      try {
        const availableInstitutions = await this.$store.dispatch('Core/fetchEnvInstitutions', { client: 'back-office' });
        this.userInstitutionsMap = availableInstitutions.reduce((carry, item) => {
          carry[item.institutionID] = {
            enabled: false,
            available_for_onboarding: false,
          };
          return carry;
        }, {});
        this.institutions = availableInstitutions;
      } catch (e) {
        this.$noty.error('Failed to fetch institutions: ', e);
      }
    },
    saveUser() {
      this.saving = true;
      this.user = utils.convertUserHasProviderEnabled(this.user);

      // get fields that were modified
      const userFieldsToUpdate = omitBy(this.user, (value, key) => this.originalData.user[key] === value);

      // Map user locations to only send its values
      if (userFieldsToUpdate.locations) {
        userFieldsToUpdate.locations = userFieldsToUpdate.locations.map(location => location.value);
      }

      let includeInstitutions = {};
      if (this.isManager(this.user) || this.isProfessional(this.user)) {
        includeInstitutions = { institutions: this.userInstitutionsMap };
      }

      axios.put(`v1/users/${this.userId}`, Object.assign({}, userFieldsToUpdate, includeInstitutions)).then(() => {
        this.$noty.success('User updated with success!');
      }).catch(err => {
        this.$noty.error(`Unable to update user: ${JSON.stringify(err.response.data.error_description)}`);
      }).finally(() => {
        this.saving = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
